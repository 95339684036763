document.addEventListener("DOMContentLoaded", () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    const menuBtn = document.getElementById('menu__btn');

    menuBtn.addEventListener('click', (e) => {
        menuBtn.classList.toggle('active');
        const top__nav__menu = document.getElementById('get__header-mobile__menu');
        top__nav__menu.classList.toggle('active');
        html.classList.toggle('menu__open');
    });
});

const productSwiper = new Swiper(".productSwiper", {
    grabCursor: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
});

const footerSwiper = new Swiper(".footerSwiper", {
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

const catSwiper = document.querySelectorAll('[id^="carousel__num-"]');
catSwiper.forEach(function (element) {
    let shopSwiper = new Swiper('#' + element.id, {
        slidesPerView: 3,
        centeredSlides: true,
        loop: true,
        initialSlide: 0,
    });
})

jQuery(document).ready($ => {
    let pageNumber = 2;
    const perPage = 9;
    const postContainer = $('#post-container');
    const loadMoreButton = $('#load-more-button');

    const loadMorePosts = () => {
        $.ajax({
            url: ajaxurl,
            type: 'POST',
            data: {
                action: 'load_more_posts',
                page: pageNumber,
                per_page: perPage
            },
            success: ({content, has_more_posts}) => {
                postContainer.append(content);
                pageNumber++;
                if (!has_more_posts) {
                    loadMoreButton.hide();
                }
            }
        });
    }

    loadMoreButton.on('click', (event) => {
        event.preventDefault();
        loadMorePosts();
    });
});


